import React, { useEffect, useMemo, useState } from "react";
import UnderHeader from "src/components/underHeader/underHeader";
import { sellerRateStyle } from "src/pages/seller-pages/seller-rate/seller-rate-style";
import SellerRateTable from "src/pages/seller-pages/seller-rate/seller-rate-table";
import SellerGlobalRateTable from "src/pages/seller-pages/seller-rate/seller-global-rate-table";
import CChart from "src/components/chart/chart";
import { Axios } from "src/api/api";
import moment from "moment";
import { toast } from "react-toastify";
import { errorMessages } from "src/helpers/error-messages";
import { useTranslation } from "react-multi-lang";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CSelect from "src/cors/select/cSelect";

let goldData = {
    1: { name: "999 ֊ 24k", order: 1 },
    2: { name: "995 ֊ 24k", order: 2 },
    3: { name: "958 ֊ 23k", order: 4 },
    4: { name: "916 ֊ 22k", order: 5 },
    5: { name: "900 ֊ 21.6k", order: 6 },
    6: { name: "875 ֊ 21k", order: 7 },
    7: { name: "750 ֊ 18k", order: 7 },
    8: { name: "585 ֊ 14k", order: 3 },
    9: { name: "500 ֊ 12k", order: 9 },
    10: { name: "416 ֊ 10k", order: 10 },
    11: { name: "375 ֊ 9k", order: 11 },
    12: { name: "333 ֊ 8k", order: 12 },
    13: { name: "999", order: 13 },
    14: { name: "995", order: 14 }
};

const RatesTest = () => {
    const classes = sellerRateStyle();
    const t = useTranslation();
    const [dataGold, setDataGold] = useState([]);
    const [dataSilver, setDataSilver] = useState([]);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [status, setStatus] = useState(true);
    const [locations, setLocations] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [ws, setWs] = useState(null);

    let global = [
        { name: t('metals.gold'), id: 1 },
        { name: t('metals.silver'), id: 2 },
        { name: t('metals.platinum'), id: 3 },
        { name: t('metals.palladium'), id: 4 },
    ];

    // WebSocket connection
    useEffect(() => {
        const socket = new WebSocket('wss://marketdata.tradermade.com/feedadv');
        setWs(socket);

        socket.onopen = () => {
            socket.send(JSON.stringify({
                userKey: "wsvyq60br1aTg073tNSA",
                symbol: 'XAUUSD,XAGUSD,XPDUSD,XPTUSD'
            }));
        };

        socket.onmessage = (event) => {
            try {
                if (event.data !== "Connected") {
                    const data = JSON.parse(event.data);
                    changeRates(data.symbol, data);
                    // Process the data as needed
                }
            } catch (error) {
                console.error("Failed to parse JSON:", error);
                console.log("Received raw data:", event.data);
            }
        };


        socket.onclose = () => {
            setTimeout(() => {
                setWs(new WebSocket('wss://marketdata.tradermade.com/feedadv'));
            }, 10000);
        };

        socket.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        // Cleanup WebSocket connection on component unmount
        return () => {
            socket.close();
        };
    }, [dataGold, dataSilver, dataGlobal]);

    useEffect(() => {
        Axios(`preorder/check`).then(res => {
            if (res.data.status === 'success') {
                setModalOpen(true);
            }
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code));
        });
    }, []);

    const useStyles = makeStyles((theme) => ({
        modal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            maxWidth: '100%',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(4),
            outline: 'none',
        },
        buttonContainer: {
            marginTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
        },
        confirmButton: {
            backgroundColor: '#d8c273',
            color: 'white',
            '&:hover': {
                backgroundColor: '#00323f',
            },
            '& span': {
                color: '#fff',
            }
        }
    }));

    const calculateRates = (globalRates, metalID, element, data) => {
        if (!globalRates) { return element }
        // Define the required coefficients inside the function
        const GlobalToLocalCoefficient = 31.1
        const GlobalTo999 = 0.015 // Assuming a default value based on context
        const Buy999 = 0.01 // Assuming a default value
        const Buy585 = 0.5 // Assuming a default value
        const Sell585 = 0.3 // Assuming a default value
        const Sell995 = 0.01 // Assuming a default value

        const sell999 = (
            globalRates?.bid / GlobalToLocalCoefficient +
            GlobalTo999
        )
        const buy585 = (
            (sell999 - Buy999) * Buy585
        )

        const sel585 = (buy585 + Sell585)
        switch (metalID) {
            case 1:
                element.sell = sell999.toFixed(3)
                element.buy = (sell999 - Buy999).toFixed(3)
                break

            case 2:
                element.sell = (sell999 - Sell995).toFixed(3)
                element.buy = (sell999 - Buy999 - Sell995).toFixed(3)
                break

            case 3:
                element.sell = (sel585 * data.Gold958Coefficient).toFixed(3)
                element.buy = (buy585 * data.Gold958Coefficient).toFixed(3)
                break

            case 4:
                element.sell = (sel585 * data.Gold916Coefficient).toFixed(3)
                element.buy = (buy585 * data.Gold916Coefficient).toFixed(3)
                break

            case 5:
                element.sell = (sel585 * data.Gold900Coefficient).toFixed(3)
                element.buy = (buy585 * data.Gold900Coefficient).toFixed(3)
                break

            case 6:
                element.sell = (sel585 * data.Gold875Coefficient).toFixed(3)
                element.buy = (buy585 * data.Gold875Coefficient).toFixed(3)
                break

            case 7:
                element.sell = (sel585 * data.Gold750Coefficient).toFixed(3)
                element.buy = (buy585 * data.Gold750Coefficient).toFixed(3)
                break

            case 8:
                element.sell = sel585.toFixed(3)
                element.buy = buy585.toFixed(3)
                break

            case 9:
                element.sell = (sel585 * data.Gold500Coefficient).toFixed(3)
                element.buy = (buy585 * data.Gold500Coefficient).toFixed(3)
                break

            case 10:
                element.sell = ((sel585 - sel585 * data.GolSubCoefficient) * data.Gold416Coefficient).toFixed(3)
                element.buy = ((buy585 - buy585 * data.GolSubCoefficient) * data.Gold416Coefficient).toFixed(3)
                break

            case 11:
                element.sell = ((sel585 - sel585 * data.GolSubCoefficient) * data.Gold375Coefficient).toFixed(3)
                element.buy = ((buy585 - buy585 * data.GolSubCoefficient) * data.Gold375Coefficient).toFixed(3)
                break

            case 12:
                element.sell = ((sel585 - sel585 * data.GolSubCoefficient) * data.Gold333Coefficient).toFixed(3)
                element.buy = ((buy585 - buy585 * data.GolSubCoefficient) * data.Gold333Coefficient).toFixed(3)
                break
        }
        element.updated_at = renderMetalTime(globalRates)
        return element
    }

    const renderMetalTime = (data) => {
        if (data) {
            if (data === undefined || isNaN(data?.ts)) { return undefined }
            return formatTimestamp(data?.ts)
        }
    }

    const getSilverRate = (rate) => {
        const r1 = rate / 10 // Equivalent to rate.QuoRem(decimal.NewFromInt(10), 0)
        let r2 = rate % 10 // The remainder when dividing by 10

        switch (true) {
            case r2 <= 2:
                r2 = 0
                break
            case r2 > 2 && r2 <= 7:
                r2 = 5
                break
            case r2 > 7:
                r2 = 10
                break
        }

        return (r1 * 10) + r2 // Equivalent to r1.Mul(decimal.NewFromInt(10)).Add(r2)
    }

    // Silver calculation in pure JavaScript
    const calculateSilverRates = (globalRates, metalID, element, coefficient) => {
        const usd = 387
        const GlobalTo999 = 0.015 // Assuming a default value based on context
        if (!globalRates) { return element }
        let sell999 = (
            (globalRates.bid / coefficient.GlobalToLocalCoefficient + GlobalTo999) * usd
        ).toFixed(3)
        sell999 = getSilverRate(sell999) // Ensure to use parseFloat before sending to custom function

        switch (metalID) {
            case 13:
                element.sell = sell999
                element.buy = (sell999 - (coefficient.Buy999 ? coefficient.Buy999 : 0)).toFixed(3)
                break
            case 14:
                element.sell = (sell999 - (coefficient.Sell995 ? coefficient.Sell995 : 0)).toFixed(3)
                element.buy = (sell999 - (coefficient.Buy999 ? coefficient.Buy999 : 0) - (coefficient.Sell995 ? coefficient.Sell995 : 0)).toFixed(3)
                break
        }
        return element
    }

    function changeRates(symbol, data) {
        let arr = [];
        let arrSilver = [];
        const coof = {
            GlobalToLocalCoefficient: 31.1,
            Gold958Coefficient: 1.64,
            Gold916Coefficient: 1.57,
            Gold900Coefficient: 1.54,
            Gold875Coefficient: 1.5,
            Gold750Coefficient: 1.28,
            Gold500Coefficient: 0.843,
            Gold416Coefficient: 0.71,
            Gold375Coefficient: 0.64,
            Gold333Coefficient: 0.569,
            GolSubCoefficient: 0.015,

            Silver999BuyCoefficient: 0.6,
            Silver995BuyCoefficient: 0.9,
            Silver995SellCoefficient: 0.3,
            RateIndexes: {
                G999: 0,
                G995: 1,
                G585: 2,
                G958: 3,
                G916: 4,
                G900: 5,
                G875: 6,
                G750: 7,
                G500: 8,
                G416: 9,
                G375: 10,
                G333: 11,
                S999: 12,
                S995: 13
            },
            Rate0Decimals: 0,
            Rate1Decimals: 1,
            Rate2Decimals: 2,
            RateDefaultDecimals: 8
        }

        if(symbol == "XAUUSD") {
            dataGold.forEach((item, index) => {
                item = calculateRates(data, item.rate_id, item, coof)
                arr.push(item);
            })
            arr.sort((a, b) => a.order - b.order);
            if(arr.length > 0) {
                setDataGold(arr);
            }
        }

        if(data.symbol == "XAGUSD") {
            dataSilver.forEach((item, index) => {
                item = calculateSilverRates(data, item.rate_id, item, coof)
                arrSilver.push(item);
            })
            if(arrSilver.length > 0) {
                setDataSilver(arrSilver);
            }
        }
        const updatedDataGlobal = dataGlobal.map((item) => {
            // Check if 'data' is defined
            if (data !== undefined && data !== null) {
                switch (data.symbol) {
                    case "XAUUSD":
                        if (item.metal_id === 1) {
                            // Change the today_price property for metal_id 1
                            return {
                                ...item,
                                today_price: data.bid,
                            };
                        }
                        break;

                    case "XAGUSD":
                        if (item.metal_id === 2) {
                            // Change the today_price property for metal_id 2
                            return {
                                ...item,
                                today_price: data.bid,
                            };
                        }
                        break;

                    case "XPDUSD":
                        if (item.metal_id === 3) {
                            // Change the today_price property for metal_id 3
                            return {
                                ...item,
                                today_price: data.bid,
                            };
                        }
                        break;

                    case "XPTUSD":
                        if (item.metal_id === 4) {
                            // Change the today_price property for metal_id 4
                            return {
                                ...item,
                                today_price: data.bid,
                            };
                        }
                        break;

                    default:
                        break;
                }
            }
            // Return the unchanged item if no conditions match
            return item;
        });

        setDataGlobal(updatedDataGlobal);
    }

    useEffect(() => {
        Axios(`rate/local`).then(res => {
            if (res.data.status === 'open') {
                setStatus(true);
            } else {
                setStatus(false);
            }

            let arr = [];
            let arrSilver = [];


            res?.data?.data.forEach((item, index) => {
                if (index < 12) {
                    arr.push({ ...item, ...goldData[item.id] });
                } else {
                    arrSilver.push({ ...item, ...goldData[item.id] });
                }
            });
            arr.sort((a, b) => a.order - b.order);
            setDataGold(arr);
            setDataSilver(arrSilver);
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code));
        });
    }, []);

    useEffect(() => {
        Axios(`rate/global`).then(res => {
            let arr = [];
            res?.data.forEach((item, index) => {
                arr.push({ ...item, name: global[index]?.name });
            });
            setDataGlobal(arr);
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code));
        });
    }, []);

    useEffect(() => {
        Axios(`preorder/locations`).then(res => {
            const formattedLocations = res.data?.markets?.map(location => ({
                label: location.title,
                value: location.id,
            }));
            setLocations(formattedLocations);
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code));
        });
    }, []);

    const handleModalConfirm = () => {
        setModalOpen(false);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const renderChart = useMemo(() => {
        return <CChart />;
    }, []);

    const modalclasses = useStyles();

    const formatTimestamp = (timestamp) => {
        const date = new Date(Number(timestamp));

        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');

        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');
        const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');

        // Simulating nanoseconds as JavaScript doesn't support more than milliseconds
        const nanoseconds = '021235581'; // You can adjust this if you have real nanoseconds data

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${nanoseconds}Z`;
    };

    return (
        <div className={classes.rateContainer}>
            <UnderHeader title={t("header.dashboard")} />
            <div className={classes.rateTableContent}>
                <div className={classes.rateTableWrapper}>
                    <div className={classes.flexContainer}>
                        <p className={classes.rateTableTitle}>{t("seller_rate.local_price")}</p>
                        <p className={classes.rateTableTitle}>{moment().format('L')}</p>
                    </div>
                    <SellerRateTable
                        status={status}
                        dataBody={dataGold}
                        title={t("metals.gold")}
                    />
                    <SellerRateTable
                        status={status}
                        dataBody={dataSilver}
                        title={t("metals.silver")}
                    />
                </div>
                <div className={classes.rateTableWrapper}>
                    <p className={classes.rateTableTitleBottom}>{t("seller_rate.the_world_spot_price")}</p>
                    <SellerGlobalRateTable
                        status={status}
                        dataBody={dataGlobal}
                        title={t("metals.metals")}
                    />
                </div>
            </div>
            <UnderHeader title={t("seller_rate.statistics")} />
            {renderChart}
            {modalOpen && (
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box className={modalclasses.modal}>
                        <Typography id="modal-title" variant="h6" component="h2" align="center">
                            Congratulations!
                        </Typography>
                        <Typography sx={{ mt: 2 }} align="center">
                            You're now able to pre-order your desired metals online.
                        </Typography>
                        <Typography sx={{ mt: 2 }} align="center">
                            Please choose the Gold Center branch where you would like to pre-order and pick up your desired metals.
                        </Typography>
                        <div style={{ marginTop: '10px' }}>
                            <CSelect
                                defaultValue={{ label: t("booking.choose_location"), value: 0 }}
                                label={t("booking.choose_location")}
                                placeholder={t("booking.choose_location")}
                                options={locations}
                            />
                        </div>
                        <Box className={modalclasses.buttonContainer}>
                            <Button onClick={handleModalConfirm} variant="contained" color="primary" className={modalclasses.confirmButton}>
                                Yes, Confirm
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
}

export default RatesTest;