import React from 'react';
import { Modal, Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'; // Import makeStyles

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

export default function ConfirmationModal({ isOpen, onConfirm, onCancel, title, message }) {
    const classes = useStyles();

    return (
        <Modal
            open={isOpen}
            onClose={onCancel}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box className={classes.modal}>
                <Typography id="modal-title" variant="h6" component="h2">
                    {title}
                </Typography>
                <Typography id="modal-description" sx={{ mt: 2 }}>
                    {message}
                </Typography>
                <Box className={classes.buttonContainer}>
                    <Button onClick={onConfirm} variant="contained" color="primary">
                        Yes, Confirm
                    </Button>
                    <Button onClick={onCancel} variant="outlined" color="secondary">
                        No, Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}
