import React, {useEffect, useState} from "react";
import {Axios} from "../../../../api/api";
import {toast} from "react-toastify";
import {errorMessages} from "../../../../helpers/error-messages";
import {useTranslation} from "react-multi-lang";

export default function History() {
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(7);
    const [loading, setLoading] = useState(false);
    const t = useTranslation();
    const [pending, setPending] = useState(false);
    const [sortConfig, setSortConfig] = useState({key: 'arrive_date', direction: 'desc'});
    const [totalGrams, setTotalGrams] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [location, setLocation] = useState('');
    const [carat, setCarat] = useState('');
    const [markets, setMarkets] = useState([]);

    const headers = [
        {key: 'order_number', label: 'Order number'},
        {key: 'firstname', label: 'Firstname'},
        {key: 'carat', label: 'Carat'},
        {key: 'grams', label: 'Grams'},
        {key: 'price', label: 'Price $'},
        {key: 'location', label: 'Location'},
        {key: 'arrive_date', label: 'Arrive Date'}
    ];

    const getOrders = (page) => {
        setLoading(true);
        Axios.get(`preorder/orders/completed`, {
            params: {
                page,
                limit: itemsPerPage,
                search: searchTerm,
                sortKey: sortConfig.key,
                sortDirection: sortConfig.direction,
                location,
                carat
            }
        })
            .then(res => {
                setOrders(res.data.orders);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            })
            .catch(err => {
                toast(errorMessages(err?.response?.data?.code));
                setLoading(false);
            });
    };

    useEffect(() => {
        const {totalGrams, totalPrice} = orders.reduce((acc, item) => {
            acc.totalGrams += item.grams;
            acc.totalPrice += item.price;
            return acc;
        }, {totalGrams: 0, totalPrice: 0});

        setTotalGrams(totalGrams);
        setTotalPrice(totalPrice);
    }, [orders]);

    useEffect(() => {
        const fetchMarkets = async () => {
            try {
                const response = await Axios.get('/preorder/admin/locations');
                setMarkets(response.data);
            } catch (error) {
                console.error("Error fetching markets:", error);
            }
        };
        fetchMarkets();
    }, []);

    const sortFixing = (data) => {
        const {key, direction} = sortConfig;
        return [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        }).filter(v => pending ? v.status === 0 : true);
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({key, direction});
    };

    const sortArrowSvg = (direction) => (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            {direction === 'desc' ?
                <path
                    d="M7.00023 5.17266L11.9502 0.222656L13.3642 1.63666L7.00023 8.00066L0.63623 1.63666L2.05023 0.222656L7.00023 5.17266Z"
                    fill="#09121F"
                />
                : <path d="M7 2L11.95 6.05L10.536 7.464L7 3.928L3.464 7.464L2.05 6.05L7 2Z" fill="#09121F"/>
            }
        </svg>
    );

    useEffect(() => {
        getOrders(currentPage);
    }, [currentPage, searchTerm, sortConfig, location, carat]); // Added location to dependencies

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderPageNumbers = () => {
        let pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <button onClick={() => handlePageChange(i)} className="page-link">
                        {i}
                    </button>
                </li>
            );
        }
        return pageNumbers;
    };

    return (
        <>
            {loading && <p>Loading...</p>}
            <div className="orders-filter tableWrapper pre-order-table" style={{marginBottom: '20px', marginTop: '20px'}}>
                <div style={{marginBottom: '20px'}}>
                    <label>Search by email</label>
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                <div style={{marginBottom: '20px'}}>
                    <label>Sort by location</label>
                    <select
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                    >
                        <option value="">All Locations</option>
                        {markets && markets?.map(market => (
                            <option value={market.id}>{market.location}</option>
                        ))}
                    </select>
                </div>

                <div>
                    <label>Sort by carat</label>
                    <select
                        value={carat}
                        onChange={(e) => setCarat(e.target.value)}
                    >
                        <option value="">All Carats</option>
                        <option value="999">999 - 24K</option>
                        <option value="995">995 - 24K</option>
                    </select>
                </div>
            </div>
            {orders && orders.length ? (
                <>
                    <div className='tableWrapper pre-order-table'>
                        <table className="table">
                            <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Total</th>
                                <th>{totalGrams.toFixed(2)} grams</th>
                                <th>${totalPrice.toFixed(2)}</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                {headers.map(({key, label}) => (
                                    <th key={key} onClick={() => requestSort(key)} style={{cursor: "pointer"}}>
                                        {label} {sortConfig.key === key && sortArrowSvg(sortConfig.direction)}
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {sortFixing(orders).map((item, index) => (
                                <tr key={index.toString()}>
                                    <td><span>Code: </span>{item.order_number}</td>
                                    <td><span>Firstname: </span>{item.firstname}</td>
                                    <td><span>Carat: </span>{item.carat === 995 ? '995 - 24K' : '999 - 24K'}</td>
                                    <td><span>Grams: </span>{item.grams.toFixed(2)}</td>
                                    <td><span>Price: </span>$ {item.price.toFixed(2)}</td>
                                    <td><span>Location: </span>{item.location}</td>
                                    <td><span>Arrive Date: </span>{new Date(item.arrive_date).toLocaleDateString()} {new Date(item.arrive_date).toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: false })}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <nav>
                        <ul className="pagination">
                            {renderPageNumbers()}
                        </ul>
                    </nav>
                </>
            ) : <p>No orders found</p>}
        </>
    );
}