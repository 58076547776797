import React from 'react';
import GlobalContextProvider from "./context/global-context";
import App from "./App";

export const GlobalContextWrapper = (props) => {
    return (
        <GlobalContextProvider>
            <App {...props}  />
        </GlobalContextProvider>
    );
};

export default GlobalContextWrapper;
