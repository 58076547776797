import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const activityCardStyle = makeStyles(() =>
    createStyles({
        activityCardContainer: {
            width: 386,
            padding: '30px 20px 20px 20px',
            boxShadow: '0 0 96px rgba(0,0,0,.1)',
            borderTopWidth: 8,
            borderTopStyle: 'solid',
            transition: '.3s',
            margin: '10px 10px',
            ["@media (max-width:1250px)"]: {
                width: '30%',
            },
            ["@media (max-width:650px)"]: {
                width: '100%',
            },
            ["@media (max-width:500px)"]: {
                borderTopWidth: 4,
                padding: '13px 20px 12px 20px',
                margin: '5px 0'
            },
        },
        title: {
            fontSize: 12,
            lineHeight: '12px',
            textTransform: 'uppercase',
            height: 60,
            overflow: 'hidden',
            color: colors.mainGreyDark,
            ["@media (max-width:500px)"]: {
                height: 23,
            },
        },
        quantity: {
            fontSize: 32,
            lineHeight: '32px',
            color: colors.blue
        }
    })
)