import React, {useState, useEffect} from 'react';
import CTab from 'src/cors/tab/cTab'; // Adjust import path
import {Axios} from 'src/api/api'; // Adjust import path
import {AxiosError} from 'axios'; // Import AxiosError
import {toast} from 'react-toastify';
import {errorMessages} from 'src/helpers/error-messages'; // Adjust import path
import './style.css';
import { useNavigate } from 'react-router-dom';

const GroupForm = ({groupToEdit = ""}) => {
    const initialGroupState = {
        discount995: 0,
        discount: 0,
        name: '',
        expired_at995: 1,
        expired_at: 1,
        quantity995: 0,
        quantity999: 0,
        users: []
    };

    const [group, setGroup] = useState(initialGroupState);
    const navigate = useNavigate();
    const [url, setUrl] = useState({url: '', type: 'post'});
    const [userState, setUserState] = useState({users: [], selectedUsers: []});
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUserSearchTerm, setSelectedUserSearchTerm] = useState(''); // New state for selected users' search term
    const [activeTab, setActiveTab] = useState(0);
    const [errors, setErrors] = useState({
        name: '',
        expired_at: '',
        expired_at995: '',
        quantity995: '',
        quantity999: '',
        discount995: '',
        discount999: '',
    });

    // State for managing checkbox selections
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    // State for managing the selected group from the dropdown
    const [selectedGroup, setSelectedGroup] = useState('');
    const [availableGroups, setAvailableGroups] = useState([]);

    useEffect(() => {
        if (groupToEdit) {
            setGroup(groupToEdit);
            setUserState({
                users: [],
                selectedUsers: groupToEdit.users || []
            });
            setUrl({url: `preorder/group/${groupToEdit.id}`, type: 'put'});
        } else {
            setGroup(initialGroupState);
            setUserState({
                users: [],
                selectedUsers: []
            });
            setUrl({url: `preorder/groups`, type: 'post'});
        }

        // Fetch available groups for the dropdown
        Axios.get('preorder/groups')
            .then(res => {
                setAvailableGroups(res.data.groups);
            })
            .catch(err => {
                toast.error('Error fetching groups');
            });
    }, [groupToEdit]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        Axios.post(`preorder/pre-order-users`, {email: e.target.value})
            .then(res => {
                const filteredUsers = res.data.users.filter(user =>
                    !userState.selectedUsers.some(selectedUser => selectedUser.id === user.id)
                );
                setUserState(prevState => ({
                    ...prevState,
                    users: filteredUsers
                }));
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(errorMessages(error.response.data));
                } else {
                    toast.error('Error fetching users');
                }
            });
    };

    const handleSelectedUserSearch = (e) => {
        setSelectedUserSearchTerm(e.target.value);
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            name: '',
            discount995: '',
            discount999: '',
            expired_at995: '',
            expired_at999: '',
            quantity995: '',
            quantity999: '',
        };

        if (!group.name.trim()) {
            newErrors.name = 'Group name is required';
            toast.error(newErrors.name);
            valid = false;
        }

        if (isNaN(group.discount995) || group.discount995 < 0) {
            newErrors.discount995 = 'Discount 995 must be a positive number';
            toast.error(newErrors.discount995);
            valid = false;
        }
        if (isNaN(group.discount) || group.discount < 0) {
            newErrors.discount999 = 'Discount 999 must be a positive number';
            toast.error(newErrors.discount999);
            valid = false;
        }

        if (group.expired_at995 === 0) {
            newErrors.expired_at995 = 'Duration 995 is required';
            toast.error(newErrors.expired_at995);
            valid = false;
        }
        if (group.expired_at === 0) {
            newErrors.expired_at = 'Duration 999 is required';
            toast.error(newErrors.expired_at);
            valid = false;
        }
        if (group.quantity995 === 0) {
            newErrors.quantity995 = 'Quantity 995 is required';
            toast.error(newErrors.quantity995);
            valid = false;
        }
        if (group.quantity999 === 0) {
            newErrors.quantity999 = 'Quantity 999 is required';
            toast.error(newErrors.quantity999);
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const createOrUpdate = (e) => {
        e.preventDefault();
        if (validateForm()) {
            Axios[url.type](url.url, {'group_info': group, 'users': userState.selectedUsers})
                .then(res => {
                    toast.success("Success");
                    navigate('/groups'); // Navigate back to the groups list
                })
                .catch(err => {
                    toast.error(errorMessages(err?.response?.data?.code));
                    console.error(err);
                });
        } else {
            toast.error("Form validation failed");
        }
    };

    const handleGroupValue = (value, input) => {
        setGroup(prevGroup => ({
            ...prevGroup,
            [input]: input === 'name' ? value : parseFloat(value) || 0,
        }));
    };

    const handleDurationSelectChange = (e, field) => {
        setGroup(prevGroup => {
            return {
                ...prevGroup,
                [field]: Number(e.target.value),
            };
        });
    };

    const handleUserSelect = (e) => {
        const selectedUserIds = Array.from(e.target.selectedOptions, option => option.value);

        setUserState(prevState => {
            const selectedUserIdsSet = new Set(selectedUserIds);
            const newSelectedUsers = prevState.users.filter(user => selectedUserIdsSet.has(user.id.toString()));
            const remainingUsers = prevState.users.filter(user => !selectedUserIdsSet.has(user.id.toString()));

            return {
                ...prevState,
                selectedUsers: [
                    ...prevState.selectedUsers,
                    ...newSelectedUsers
                ],
                users: remainingUsers
            };
        });
    };

    const handleRemoveUser = (userId) => {
        setUserState(prevState => {
            const userToRemove = prevState.selectedUsers.find(user => user.id === userId);
            return {
                ...prevState,
                selectedUsers: prevState.selectedUsers.filter(user => user.id !== userId),
                users: [...prevState.users, userToRemove]
            };
        });
    };

    const handleCheckboxChange = (userId) => {
        setSelectedCheckboxes(prevSelected => {
            if (prevSelected.includes(userId)) {
                return prevSelected.filter(id => id !== userId);
            } else {
                return [...prevSelected, userId];
            }
        });
    };

    const handleGroupChangeSelect = (e) => {
        setSelectedGroup(e.target.value);
    };

    const handleChangeGroup = () => {
        const selectedUsers = userState.selectedUsers.filter(user => selectedCheckboxes.includes(user.id));

        if (selectedUsers.length > 0 && selectedGroup) {
            // Call API to change group of selected users
            Axios.post(`preorder/change-group`, {
                user_ids: selectedUsers.map(user => user.id),
                new_group_id: Number(selectedGroup)
            })
                .then(() => {
                    toast.success('Group changed for selected users');
                    // Optionally clear the selected checkboxes and group selection
                    setSelectedCheckboxes([]);
                    setSelectedGroup('');
                })
                .catch(err => {
                    toast.error('Error changing group');
                });
        } else if (!selectedGroup) {
            toast.error('Please select a group to change to');
        } else {
            toast.error('No users selected');
        }
    };

    const filteredSelectedUsers = userState.selectedUsers.filter(user =>
        user.email.toLowerCase().includes(selectedUserSearchTerm.toLowerCase())
    );

    return (
        <form onSubmit={createOrUpdate} className="group-form">
            <h2 style={{marginBottom: "25px"}}>{groupToEdit ? `Edit Group: ${group.name}` : 'Create Group'}</h2>
            <div>
                <label>Group Name:</label>
                <input
                    type="text"
                    name="name"
                    value={group.name}
                    onChange={(e) => handleGroupValue(e.target.value, 'name')}
                />
                {errors.name && <span style={{color: 'red'}}>{errors.name}</span>}
            </div>
            <div className="tabs-header">
                <CTab
                    onPress={setActiveTab}
                    items={[
                        {key: 'tab1', value: 'Gold 995'},
                        {key: 'tab2', value: 'Gold 999'},
                        {key: 'tab3', value: 'User Selection'},
                    ]}
                    active={activeTab}
                />
            </div>

            <div style={{display: activeTab === 0 ? 'block' : 'none'}}>
                <div>
                    <label>Discount:</label>
                    <input
                        type="number"
                        step="0.01"
                        value={group.discount995}
                        onChange={(e) => handleGroupValue(e.target.value, 'discount995')}
                    />
                    {errors.discount995 && <span style={{color: 'red'}}>{errors.discount995}</span>}
                </div>
                <div>
                    <label>Duration:</label>
                    <select
                        value={group.expired_at995}
                        onChange={(e) => handleDurationSelectChange(e, 'expired_at995')}
                    >
                        <option value="{0}">Select duration</option>
                        <option value={1}>24 hours</option>
                        <option value={2}>48 hours</option>
                    </select>
                    {errors.expired_at995 && <span style={{color: 'red'}}>{errors.expired_at995}</span>}
                </div>
                <div>
                    <label>Quantity:</label>
                    <input
                        type="text"
                        value={group.quantity995}
                        onChange={(e) => handleGroupValue(e.target.value, 'quantity995')}
                    />
                    {errors.quantity995 && <span style={{color: 'red'}}>{errors.quantity995}</span>}
                </div>
            </div>

            <div style={{display: activeTab === 1 ? 'block' : 'none'}}>
                <div>
                    <label>Duration:</label>
                    <select
                        value={group.expired_at}
                        onChange={(e) => handleDurationSelectChange(e, 'expired_at')}
                    >
                        <option value="{0}">Select duration</option>
                        <option value={1}>24 hours</option>
                        <option value={2}>48 hours</option>
                    </select>
                    {errors.expired_at && <span style={{color: 'red'}}>{errors.expired_at}</span>}
                </div>
                <div>
                    <label>Discount:</label>
                    <input
                        type="number"
                        step="0.01"
                        value={group.discount}
                        onChange={(e) => handleGroupValue(e.target.value, 'discount')}
                    />
                    {errors.discount999 && <span style={{color: 'red'}}>{errors.discount999}</span>}
                </div>
                <div>
                    <label>Quantity:</label>
                    <input
                        type="text"
                        value={group.quantity999}
                        onChange={(e) => handleGroupValue(e.target.value, 'quantity999')}
                    />
                    {errors.quantity999 && <span style={{color: 'red'}}>{errors.quantity999}</span>}
                </div>
            </div>

            <div style={{display: activeTab === 2 ? 'block' : 'none'}}>
                <div>
                    <label>Search Users:</label>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearch}
                        placeholder="Search users..."
                    />
                </div>
                <div>
                    <label>Available Users:</label>
                    <select
                        multiple
                        value={userState.selectedUsers.map(user => user.id)}
                        onChange={handleUserSelect}
                        style={{height: '150px'}}
                    >
                        {userState.users.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.email}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Search Selected Users:</label>
                    <input
                        type="text"
                        value={selectedUserSearchTerm}
                        onChange={handleSelectedUserSearch}
                        placeholder="Search selected users..."
                    />
                </div>
                <div>
                    <label>Selected Users:</label>
                    <ul className="flexRow">
                        {filteredSelectedUsers.map(user => (
                            <li key={user.id} style={{marginLeft: 7, display: 'flex', alignItems: 'center'}}>
                                <input
                                    type="checkbox"
                                    checked={selectedCheckboxes.includes(user.id)}
                                    onChange={() => handleCheckboxChange(user.id)}
                                />
                                {user.email}
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{cursor: 'pointer', marginLeft: '8px'}}
                                    onClick={() => handleRemoveUser(user.id)}
                                >
                                    <path
                                        d="M6 4.48528L10.2426 0.242641L11.6569 1.65685L7.41421 5.8995L11.6569 10.1421L10.2426 11.5564L6 7.31371L1.75736 11.5564L0.343146 10.1421L4.58579 5.8995L0.343146 1.65685L1.75736 0.242641L6 4.48528Z"
                                        fill="#7C7C7C"
                                    />
                                </svg>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <label>Select Group to Move Users:</label>
                    <select
                        value={selectedGroup}
                        onChange={handleGroupChangeSelect}
                    >
                        <option value="">Select a group</option>
                        {availableGroups.map(group => (
                            <option key={group.id} value={group.id}>
                                {group.name}
                            </option>
                        ))}
                    </select>
                </div>
                <button type="button" onClick={handleChangeGroup}>Change Group for Selected Users</button>
            </div>

            <button type="submit">Submit</button>
        </form>
    );
};

export default GroupForm;
