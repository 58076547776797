import React from 'react';
import {activityCardStyle} from "./activityCard-style";

interface ActivityCardProps {
    activityCardData: any
}

const ActivityCard = ({activityCardData}: ActivityCardProps) => {
    const classes = activityCardStyle();

    return (
        activityCardData && activityCardData.map((activityItem: any, index: number) => {
            return (
                <div style={{borderTopColor: `${activityCardData[index]?.topColor}`}} key={index.toString()}
                     className={classes.activityCardContainer}>
                    <p className={classes.title}>{activityItem.title}</p>
                    <p className={classes.quantity}>{activityItem.quantity}</p>
                </div>
            )
        })
    )
}

export default ActivityCard