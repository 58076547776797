// src/pages/MarketIndex.js
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Axios} from "../../../api/api";
import { Link } from 'react-router-dom';
import CButton from "../../../cors/button/cButton";
import {toast} from "react-toastify";
import {errorMessages} from "../../../helpers/error-messages";
import {GlobalContext} from "../../../context/global-context";
import ConfirmationModal from "../../../components/confimationModal/confirmationModal";

const MarketIndex = () => {
    const [markets, setMarkets] = useState([]);
    const { state, setState } = useContext(GlobalContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedMarketId, setSelectedMarketId] = useState(null);

    const handleDeleteClick = (id) => {
        setSelectedMarketId(id);
        setModalOpen(true);
    };

    const handleModalConfirm = () => {
        onDelete(selectedMarketId);
        setModalOpen(false);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        const fetchMarkets = async () => {
            try {
                const response = await Axios.get('/preorder/admin/locations');
                setMarkets(response.data);
            } catch (error) {
                console.error("Error fetching markets:", error);
            }
        };
        fetchMarkets();
    }, []);

    function onDelete(id) {
        const updatedMarkets = markets.filter(market => market.id !== id);
        setMarkets(updatedMarkets)
        Axios.delete(`preorder/admin/location/${id}`).then(res => {
            request()
        }).catch(err => {
            console.error(err)
        })
    }

    const request = useCallback(() => {
        setState({ ...state, loader: true });
        Axios('preorder/admin/locations').then(res => {
            setMarkets(res.data.locations);
        }).catch(err => {
            console.error(err);
            toast(errorMessages(err?.response?.data?.code));
        }).finally(() => {
            setState({ ...state, loader: false });
        });
    }, [state, setState]);

    return (
        <div className='tableWrapper pre-order-table' style={{marginTop: '15px'}}>
            <div className="cardItem">
                <h1>Markets</h1>
                <Link to={`/markets/create`} style={{maxWidth: '150px'}}>
                    <CButton
                        title="Create New Market"
                        borderWidth={1}
                    />
                </Link>
            </div>
            <table className="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {markets && markets?.map(market => (
                    <tr key={market.id}>
                        <td><span>Name: </span>{market.company_name}</td>
                        <td><span>Location: </span>{market.location}</td>
                        <td>
                            <Link to={`/markets/edit/${market.id}`}>
                                <CButton
                                    title="Edit"
                                    borderWidth={1}
                                />
                            </Link>
                            <br />
                            <CButton
                                onPress={() => handleDeleteClick(market.id)}
                                title="Delete"
                                borderWidth={1}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            {modalOpen && (
                <ConfirmationModal
                    isOpen={modalOpen}
                    onConfirm={handleModalConfirm}
                    onCancel={handleModalClose}
                    title="Confirm Cancelation"
                    message="Are you sure you want to cancel this order?"
                />
            )}
        </div>
    );
};

export default MarketIndex;
