import React, { useState, useEffect } from 'react';
import { Axios } from "../../../api/api";
import { useNavigate, useParams } from 'react-router-dom';
import {toast} from "react-toastify";

const MarketEdit = () => {
    const { id } = useParams(); // Get market ID from URL
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        password: '',
        email: '',
        company_name: '',
        location: '',
        working_hours: {},
        working_days: []
    });
    const hoursOptions = [
        "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00",
        "16:00", "17:00", "18:00", "19:00", "20:00", "21:00"
    ];

    // Define days options
    const daysOptions = [
        "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
    ];
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch existing market data for editing
        const fetchMarket = async () => {
            try {
                const response = await Axios.get(`/preorder/admin/location/${id}`);

                // Parse working_hours and working_days
                const workingHours = response.data.working_hours ? JSON.parse(response.data.working_hours) : {};
                const workingDays = response.data.working_days ? response.data.working_days.replace(/[{}]/g, '').split(',') : [];

                // Set form data
                setFormData({
                    ...response.data,
                    working_hours: workingHours,
                    working_days: workingDays
                });
            } catch (error) {
                console.error("Error fetching market data:", error);
            }
        };

        fetchMarket();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleDaysChange = (e) => {
        const { value, checked } = e.target;
        setFormData(prevState => {
            const working_days = checked
                ? [...prevState.working_days, value]  // Add day to array
                : prevState.working_days.filter(day => day !== value);  // Remove day from array

            // Initialize working_hours for the newly selected day
            const working_hours = { ...prevState.working_hours };
            if (checked) {
                working_hours[value] = [];  // Initialize with an empty array for new days
            } else {
                delete working_hours[value];  // Remove hours for unselected days
            }

            return { ...prevState, working_days, working_hours };
        });
    };

    const handleHoursChange = (day, e) => {
        const { value, checked } = e.target;
        setFormData(prevState => {
            const working_hours = { ...prevState.working_hours };
            if (checked) {
                working_hours[day] = [...(working_hours[day] || []), value];
            } else {
                working_hours[day] = (working_hours[day] || []).filter(hour => hour !== value);
            }

            return { ...prevState, working_hours };
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Convert working_hours and working_days arrays to JSON for database storage
            const updatedFormData = {
                ...formData,
                working_hours: JSON.stringify(formData.working_hours),
                working_days: JSON.stringify(formData.working_days)
            };

            await Axios.put(`/preorder/admin/location/${id}`, formData).then((res) => {
                if(res.status === 200) {
                    toast.success('Market updated successfully')
                    navigate('/markets');
                } else {
                    toast.error('Internal server error')
                }
            });

        } catch (error) {
            console.error("Error updating market:", error);
        }
    };

    const sortedWorkingDays = formData.working_days.slice().sort((a, b) => daysOptions.indexOf(a) - daysOptions.indexOf(b));

    return (
        <div>
            <h1>Edit Market</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    First Name:
                    <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Last Name:
                    <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Phone Number:
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Email:
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Company Name:
                    <input
                        type="text"
                        name="company_name"
                        value={formData.company_name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Location:
                    <input
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Working Days:
                    <div className="flexRow" style={{marginTop: 10}}>
                        {daysOptions.map(day => (
                            <label key={day} style={{marginLeft: 15}}>
                                <input
                                    type="checkbox"
                                    value={day}
                                    checked={formData.working_days?.includes(day)}
                                    onChange={handleDaysChange}
                                />
                                {day}
                            </label>
                        ))}
                    </div>
                </label>
                {sortedWorkingDays.length > 0 && (
                    <div>
                        {sortedWorkingDays.map(day => (
                            <div key={day}>
                                <h3>{day} Working Hours</h3>
                                <div style={{marginTop: 10}} className="flexRow">
                                    {hoursOptions.map(hour => (
                                        <label key={hour} style={{marginLeft: 10}}>
                                            <input
                                                type="checkbox"
                                                value={hour}
                                                checked={formData.working_hours[day]?.includes(hour) || false}
                                                onChange={(e) => handleHoursChange(day, e)}
                                            />
                                            {hour}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <button type="submit">Update</button>
            </form>
        </div>
    );
};

export default MarketEdit;