import React from "react";
import {Routes, Route} from "react-router-dom";
import Messages from "../pages/admin-pages/messages/messages";
import SellerOrderItem from "../pages/seller-pages/seller-orderItem/seller-order-item";
import SellerOrder from "../pages/seller-pages/seller-order/seller-order";
// import SellerOffer from "../pages/seller-pages/seller-offers/index";
import SellerSold from "../pages/seller-pages/seller-sold/seller-sold";
import SellerProduct from "../pages/seller-pages/seller-product/seller-product";
import SellerAddProduct from "../pages/seller-pages/seller-add-product/seller-add-product";
import SellerEditProduct from "../pages/seller-pages/seller-add-product/seller-edit-product";
import SellerViewProduct from "../pages/seller-pages/seller-add-product/seller-view-product";
import SellerSettings from "../pages/seller-pages/seller-settings/seller-settings";
import SellerTools from "../pages/seller-pages/seller-tools/seller-tools";
import FakeJewelrySeller from "../pages/admin-pages/fake-jewelry/seller-fake-jewelry";
import SellerRate from "../pages/seller-pages/seller-rate/seller-rate";
import SellerCustomPrice from "../pages/seller-pages/seller-custom-price/seller-custom-price";
import SellerCustomPriceEdit from "../pages/seller-pages/seller-custom-price/seller-custom-price-edit";
import HeaderSeller from "../components/header/headerSeller";
import SellerWarehouse from "../pages/seller-pages/seller-warehouse/seller-warehouse";
import SellerOfferContextWrapper from "../pages/seller-pages/seller-offers";
import SellerBooking from "../pages/seller-pages/seller-booking/seller-booking";
import SellerTemplates from "../pages/seller-pages/seller-templates/seller-templates";
import SellerTemplateSingle from "../pages/seller-pages/seller-add-product/seller-template-single";


export default function SellerRole() {
    return (
        <>
            <HeaderSeller/>
            <Routes>
                <Route path='/dashboard' element={<SellerRate/>}/>
                <Route path='/seller/messages' element={<Messages/>}/>
                <Route path='/sellerOrderItem' element={<SellerOrderItem/>}/>
                <Route path='/sellerOrder' element={<SellerOrder/>}/>
                <Route path='/sellerOffer' element={<SellerOfferContextWrapper/>}/>
                <Route path='/sellerSold' element={<SellerSold/>}/>
                <Route path='/sellerProduct' element={<SellerProduct/>}/>
                <Route path='/sellerProduct/sellerAddProduct' element={<SellerAddProduct/>}/>
                <Route path='/seller-template-single' element={<SellerTemplateSingle/>}/>
                <Route path='/sellerProduct/sellerEditProduct/:id' element={<SellerEditProduct/>}/>
                <Route path='/customPrice/customPriceEdit/sellerEditProduct/:id' element={<SellerEditProduct/>}/>
                <Route path='/sellerProduct/sellerViewProduct/:id' element={<SellerViewProduct/>}/>
                <Route path='/sellerSettings' element={<SellerSettings/>}/>
                <Route path='/sellerTools' element={<SellerTools/>}/>
                <Route path='/seller-booking' element={<SellerBooking/>}/>
                <Route path='/seller/fakeJewelry' element={<FakeJewelrySeller/>}/>
                <Route path='/customPrice' element={<SellerCustomPrice/>}/>
                <Route path='/customPrice/customPriceEdit' element={<SellerCustomPriceEdit/>}/>
                <Route path='/seller-warehouse' element={<SellerWarehouse/>}/>
                <Route path='/seller-templates' element={<SellerTemplates/>}/>
            </Routes>
        </>
    );
}

