import React, {useEffect, useState} from "react";
import {Axios} from "../../../../api/api";
import {toast} from "react-toastify";
import {errorMessages} from "../../../../helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import CButton from "../../../../cors/button/cButton";
import {useNavigate} from "react-router-dom";
import deleteIcon from "src/assets/icons/deleteIcon.svg";
import iconCheck from "src/assets/icons/iconCheck.svg";
import ConfirmationModal from "../../../../components/confimationModal/confirmationModal"; // Assuming you have this component


export default function PendingOrders() {
    const [orders, setOrders] = useState([])
    const [pending, setPending] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const t = useTranslation();
    const navigation = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(7);
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'arrive_date', direction: 'desc' });
    const [totalGrams, setTotalGrams] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const sortArrowSvg = (direction) => (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            {direction === 'desc' ?
                <path
                    d="M7.00023 5.17266L11.9502 0.222656L13.3642 1.63666L7.00023 8.00066L0.63623 1.63666L2.05023 0.222656L7.00023 5.17266Z"
                    fill="#09121F"
                />
                : <path d="M7 2L11.95 6.05L10.536 7.464L7 3.928L3.464 7.464L2.05 6.05L7 2Z" fill="#09121F"/>
            }
        </svg>
    );
    const sortFixing = (data) => {
        const { key, direction } = sortConfig;
        return [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        }).filter(v => pending ? v.status === 0 : true);
    };

    const getOrders = (page) => {
        setLoading(true);
        Axios.get(`preorder/orders/pending`, {
            params: {
                page,
                limit: itemsPerPage,
                search: searchTerm, // Include search term in the request
                sortKey: sortConfig.key,
                sortDirection: sortConfig.direction
            }
        })
            .then(res => {
                setOrders(res.data.orders);
                setTotalPages(res.data.total_pages);  // Ensure your API returns totalPages
                setLoading(false);
            })
            .catch(err => {
                toast(errorMessages(err?.response?.data?.code));
                setLoading(false);
            });
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        getOrders(currentPage);
    }, [currentPage, searchTerm, sortConfig]);

    useEffect(() => {
        const { totalGrams, totalPrice } = orders.reduce((acc, item) => {
            acc.totalGrams += item.grams;
            acc.totalPrice += item.price;
            return acc;
        }, { totalGrams: 0, totalPrice: 0 });

        setTotalGrams(totalGrams);
        setTotalPrice(totalPrice);
    }, [orders]);

    const headers = [
        { key: 'firstname', label: 'Firstname' },
        { key: 'carat', label: 'Carat' },
        { key: 'grams', label: 'Grams' },
        { key: 'price', label: 'Price $' },
        { key: 'location', label: 'Location' },
        { key: 'arrive_date', label: 'Arrive Date' }
    ];

    const handleApprove = (id) => {
        Axios.put(`preorder/order/${id}/completed`)
            .then((res) => {
                toast.success("Order approved successfully");
                getOrders();
            })
            .catch((err) => {
                toast.error(errorMessages(err?.response?.data?.code));
            });
    };

    const handleCancel = (id) => {
        Axios.put(`preorder/order/${id}/canceled`)
            .then((res) => {
                toast.success("Order canceled successfully");
                getOrders();
            })
            .catch((err) => {
                toast.error(errorMessages(err?.response?.data?.code));
            });
    };

    const handleDeleteClick = (id) => {
        setSelectedOrderId(id);
        setModalOpen(true);
    };

    const handleModalConfirm = () => {
        handleCancel(selectedOrderId);
        setModalOpen(false);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderPageNumbers = () => {
        let pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <button onClick={() => handlePageChange(i)} className="page-link">
                        {i}
                    </button>
                </li>
            );
        }
        return pageNumbers;
    };

    return (
        <>
            {loading && <p>Loading...</p>}
            <div style={{ marginBottom: '20px' }}>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            {
                (orders && orders.length) ?
                    <>
                        <div className='tableWrapper pre-order-table' style={{marginTop: '30px'}}>
                            <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(29,56,101,0.1)', paddingBottom: '20px' }}>
                                <h3>Pending Orders</h3>
                            </div>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Total</th>
                                    <th>{totalGrams.toFixed(2)} grams</th>
                                    <th>${totalPrice.toFixed(2)}</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th></th>
                                    {headers.map(({ key, label }) => (
                                        <th key={key} onClick={() => requestSort(key)} style={{cursor:"pointer"}}>
                                            {label} {sortConfig.key === key && sortArrowSvg(sortConfig.direction)}
                                        </th>
                                    ))}
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    sortFixing(orders).map((item, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td></td>
                                                <td>{item.firstname}</td>
                                                <td>{item.carat === 995 ? '995 - 24K' : '999 - 24K'}</td>
                                                <td>{item.grams.toFixed(2)}</td>
                                                <td>$ {item.price.toFixed(2)}</td>
                                                <td>{item.location}</td>
                                                <td><span>Arrive Date: </span>{new Date(item.arrive_date).toLocaleDateString()} {new Date(item.arrive_date).toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: false })}</td>
                                                <td>
                                                    <div className="flexRow">
                                                        <CButton
                                                            onPress={() => handleApprove(item.id)}
                                                            leftIcon={<img src={iconCheck} alt="approve" />}
                                                            borderWidth={1}
                                                        />
                                                        <span>&nbsp;</span>
                                                        <CButton
                                                            onPress={() => handleDeleteClick(item.id)}
                                                            leftIcon={<img src={deleteIcon} alt="delete" />}
                                                            borderWidth={1}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {modalOpen && (
                            <ConfirmationModal
                                isOpen={modalOpen}
                                onConfirm={handleModalConfirm}
                                onCancel={handleModalClose}
                                title="Confirm Cancelation"
                                message="Are you sure you want to cancel this order?"
                            />
                        )}
                    </> : null
            }
            <nav>
                <ul className="pagination">
                    {renderPageNumbers()}
                </ul>
            </nav>
        </>
    );
}

