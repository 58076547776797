import React, {useEffect, useState} from "react";
import {dashboardStyle} from "./dashboard-style";
import ActivityCard from "src/components/activityCard/activityCard";
import {colors} from "src/utils/color";
import DashboardTable from "./dashboardTable/dashboardTable";
import UnderHeader from "src/components/underHeader/underHeader";
import {Axios} from "src/api/api";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import ConfirmationModal from "../../../components/confimationModal/confirmationModal";

function Dashboard() {
    const classes = dashboardStyle()
    const [orders, setOrders] = useState([])
    const [activity, setActivity] = useState({})
    const t = useTranslation()

    const [isEditing, setIsEditing] = useState(false);
    const [newMaxGoldLimit, setNewMaxGoldLimit] = useState();

    const handleEditClick = () => {
        setIsEditing(true);
        // setNewMaxGoldLimit(newMaxGoldLimit); // Initialize with current value
    };

    const handleSave = () => {
        Axios.put(`/preorder/update-max-gold-limit`, { quantity: newMaxGoldLimit })
            .then(res => {
                setNewMaxGoldLimit(newMaxGoldLimit);
                setIsEditing(false);
                toast.success(t("dashboard.limit_updated"));
            })
            .catch(err => {
                toast.error(errorMessages(err?.response?.data?.code));
            });
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const activityCardData = [
        {
            title: t("dashboard.today_new_orders"),
            quantity: activity?.todayOrdersCount? activity?.todayOrdersCount : 0,
            topColor: colors.grayishBlue
        },
    ]

    useEffect(() => {
        Axios(`preorder/admin/preorders`).then(res => {
            setOrders(res.data.orders)
            setActivity(res.data)
            console.log(activity)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })

        Axios(`preorder/get-max-gold-limit`).then(res => {
            setNewMaxGoldLimit(res.data.quantity)
            console.log(res.data.quantity)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, [])

    return (
        <div className={classes.dashboardContainer}>
            <UnderHeader title={t("header.dashboard")} />
            <div className={classes.activitySection}>
                <div className='space-between-container'>
                    <p className='mainTitle'>{t("dashboard.activity")}</p>
                </div>
                <div className={classes.wrapper}>
                    <ActivityCard activityCardData={activityCardData}/>
                    <div className={classes.limitSection}>
                        <p style={{fontSize: '12px', color:'#7C7C7C'}}>gold max limit(grams)</p>
                        <input
                            type="number"
                            value={newMaxGoldLimit}
                            onChange={(e) => setNewMaxGoldLimit(parseFloat(e.target.value))}
                            className={classes.editableInput}
                            step="0.01"
                            style={{border: 'none', background: 'transparent', fontSize: '32px'}}
                        />
                        <button onClick={handleEditClick} className={classes.editButton} style={{fontSize: '12px'}}>
                            Edit
                        </button>
                    </div>
                </div>
            </div>
            {orders?.length > 0 && <div className={classes.tableSection}>
                <div className='space-between-container'>
                    <p className='mainTitle'>{t("dashboard.order_list")}</p>
                    <Link to={'/order'}>
                        <p className='see-more-btn'>{t("dashboard.see_more")}</p>
                    </Link>
                </div>
                <DashboardTable dataBody={orders}/>
            </div>}
            {isEditing && (
                <ConfirmationModal
                    isOpen={isEditing}
                    onConfirm={handleSave}
                    onCancel={handleCancel}
                    title="Confirm Cancelation"
                    message="Are you sure you want to change max available?"
                />
            )}
        </div>
    );
}

export default Dashboard;